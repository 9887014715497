<template>
  <div class="container">
    <div class="container_item" v-for="(item, i) in list" :key="i" @click="toActivityDetail(item.id)">
      <img :src="item?.picUrl" alt="">
      <div class="container_item_content">
        <div class="container_item_content_title">{{ item?.name }}</div>
        <div class="container_item_content_center">

          <div  :class="[item?.status=='0'?'container_item_content_center_status0':(item?.status=='1'?'container_item_content_center_status1':(item?.status=='2'?'container_item_content_center_status2':'container_item_content_center_status3'))]">
            {{ item?.status=='0'?'已结束':(item?.status=='1'?'进行中':(item?.status=='2'?'报名中':'未开始')) }}
          </div>

          <div class="container_item_content_center_score">可{{ item?.scoreType=='1'?'获得':'消耗' }}{{ item?.score }}积分</div>
        </div>
        <div class="container_item_content_bottom">
          <div class="container_item_content_bottom_num">已报名：<span style="color:#FF9100;">{{item.alreaySignUpNum}}</span>/{{item.limitNum}}</div>
          <div class="container_item_content_bottom_address">{{ item?.addressDetail?.length>10?(item?.addressDetail?.slice(0,10)+'...'):item?.addressDetail }}</div>
        </div>
      </div>

    </div>
    <div class="container_type">
      <div class="container_type_item" v-for="(type,index) in actTypeList" @click="toActivityTypeList(type.id)"
           :key="index">
        <img :src="type.icon?type.icon:actTypePic" alt="">
        <div class="container_type_item_name">
          {{type.name}}
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router"
import {ref} from "vue";
import {getHotActivityList} from "@/api/activity";
import {getActivityTypeList} from "@/api/home";
import {useStore} from "vuex";

const router = useRouter();
//活动类型默认图片
const actTypePic = ref('../../assets/images/activity/2.png');
const actTypeList = ref([])
//获得活动分类列表
const getActTypeList = async () => {
  let response = await getActivityTypeList();
  if (response.rows.length > 0) {
    actTypeList.value = response.rows
  } else {
    actTypeList.value = [];
  }
}
getActTypeList()



//分页
const query = ref({})
query.value = {pageNum: 1, pageSize: 7}
const store = useStore();
const list = ref([]);
const activityList = async () => {
  let response = await getHotActivityList(query.value, {oper: "1",checkStatus:"1",stationId:store.state.stationId});
  console.log(response.rows)
  if (response.rows.length > 0) {
    for (const e of response.rows) {
      list.value.push(e.activity)
    }

  }
}
activityList();
const toActivityDetail=(id)=>{
  router.push({
    path: 'activityDetail',
    query: {
      id,
    },
  })
}
const toActivityTypeList=(activityTypeId)=>{
  router.push({
    path: 'activityTypeList',
    query: {
      activityTypeId,
    },
  })
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #F5F5F5;
  padding: 60px;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  &_item {
    margin:20px;
    width: calc(25% - 40px);
    height: 410px;
    border-radius: 6px 6px 6px 6px;
    background-color: #FFFFFF;

    img {
      width: 400px;
      height: 200px;
    }

    &_content {
      padding: 20px 20px 38px 20px;
      border-radius: 0 0 6px 6px;
      display: flex;
      flex-direction: column;

      &_title {
        top: 20px;
        line-height: 30px;
        color: rgba(33, 33, 33, 100);
        font-size: 20px;
        text-align: left;
        font-family: HarmonyOS_Sans_SC-medium;
      }

      &_center {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_status0{
          width: 55px;
          line-height: 26px;
          border-radius: 2px;
          background-color: rgba(33, 33, 33, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          font-size: 14px;
          font-family: PingFangSC-medium;
          font-weight: bolder;
        }
        &_status1{
          width: 55px;
          line-height: 26px;
          border-radius: 2px;
          background-color: rgba(0, 230, 118, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          font-size: 14px;
          font-family: PingFangSC-medium;
          font-weight: bolder;
        }
        &_status2{
          width: 55px;
          line-height: 26px;
          border-radius: 2px;
          background-color: rgba(98, 212, 204, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          font-size: 14px;
          font-family: PingFangSC-medium;
          font-weight: bolder;
        }
        &_status3{
          width: 55px;
          line-height: 26px;
          border-radius: 2px;
          background-color: rgba(255, 153, 89, 100);
          text-align: center;
          color: rgba(255, 255, 255, 100);
          font-size: 14px;
          font-family: PingFangSC-medium;
          font-weight: bolder;
        }

        &_score {
          line-height: 26px;
          color: rgba(33, 33, 33, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-medium;
        }
      }

      &_bottom {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_num {
          line-height: 16px;
          color: rgba(97, 97, 97, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-regular;
        }

        &_address {
          line-height: 16px;
          color: rgba(189, 189, 189, 100);
          font-size: 16px;
          text-align: left;
          font-family: PingFangSC-regular;
        }
      }
    }
  }

  &_type {
    margin:20px;
    width: calc(25% - 40px);
    border-radius: 6px 6px 6px 6px;
    background-color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;

    &_item {
      margin-left: 40px;
      margin-top: 52px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 108px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }

      &_name {
        margin-top: 10px;
        line-height: 18px;
        color: rgba(33, 33, 33, 100);
        font-size: 18px;
        text-align: center;
        font-family: PingFangSC-medium;
      }
    }
  }
}
</style>
